import React, { Component } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import qs from "qs";
import { Helmet } from "react-helmet";

// Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import logo from "./logo.png";
import flag_nl from "./nl.png";
import flag_fr from "./fr.png";

import { CustomInputComponent, Measurements, WelkeVerdiepen } from "./components";
import FileUpload from "./components/FileUpload";
import Spinner from "./components/Spinner";
import ErrorFocus from "./components/ErrorFocus";

//translations
import messages from "./translations";
import { IntlProvider, FormattedMessage } from "react-intl";

const validationSchema = Yup.object().shape({
  naam_contactpersoon: Yup.string().required("Verplicht"),
  factuur_naam: Yup.string().required("Verplicht"),
  // factuur_juridische: Yup.string().required("Verplicht"),
  factuur_straat: Yup.string().required("Verplicht"),
  factuur_postcode: Yup.string().required("Verplicht"),
  factuur_gemeente: Yup.string().required("Verplicht"),
  gsm_1: Yup.string().required("Verplicht"),
  email: Yup.string()
    .email("Ongeldig e-mail")
    .required("Verplicht"),
  // taxRate: Yup.string().required("Verplicht"),
  btwNrVal: Yup.string().when("taxRate", {
    is: val => val === "btwNr",
    then: Yup.string().required("Verplicht")
  }),

  werfadres_straat: Yup.string().when("idem_gegevens", {
    is: !true,
    then: Yup.string().required("Verplicht")
  }),
  werfadres_postcode: Yup.string().when("idem_gegevens", {
    is: !true,
    then: Yup.string().required("Verplicht")
  }),
  werfadres_gemeente: Yup.string().when("idem_gegevens", {
    is: !true,
    then: Yup.string().required("Verplicht")
  })
});

const initialValues = {
  quotationID: "",
  projectManager: "",
  naam_contactpersoon: "",
  werfadres_straat: "",
  werfadres_postcode: "",
  werfadres_gemeente: "",
  gsm_1: "",
  gsm_2: "",
  check_in_at_work_nr: "",
  project_naam: "",
  referentie: "",
  email: "",
  factuur_naam: "",
  factuur_juridische: "",
  factuur_straat: "",
  factuur_postcode: "",
  factuur_gemeente: "",
  idem_gegevens: false,
  taxRate: "",
  btwNrVal: "",
  canPlaceTruck: "",
  parkingBanRequired: "",
  waterPresentOnSite: "",
  mixerCleaningPossible: "",
  WerkenZijn: "",
  welkeVerdiepen: ["", "", "", "", "", "", ""],
  chape: [
    {
      oppervlakte: "",
      geschuurd: "",
      dikte: ""
    }
  ],
  akoestische_isolatie: [
    {
      oppervlakte: "",
      geschuurd: "",
      dikte: ""
    }
  ],
  gespoten_pur_isolatie: [
    {
      oppervlakte: "",
      geschuurd: "",
      dikte: "",
      afschuren: "",
      aftoppen: "",
      vloerverwarming: ""
    }
  ],
  schuimbeton: [
    {
      oppervlakte: "",
      geschuurd: "",
      dikte: ""
    }
  ],
  beton: [
    {
      oppervlakte: "",
      poly_beton: "",
      geschuurd: "",
      dikte: ""
    }
  ],
  eps: [
    {
      oppervlakte: "",
      geschuurd: "",
      dikte: ""
    }
  ],
  extra_info: "",
  filepond: [],
  attachments: []
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addExtraInfo: false,
      loading: false,
      success: false,
      error: false,
      checkInAtWorkFiles: 0,
      mapsScreenshotFiles: 0,
      neemFotoVanDeStraatFiles: 0,
      neemFotoVanDeWerfFiles: 0,
      neemFotoVanDeWoningFiles: 0,
      neemFotoVanDeWerkenFiles: 0,
      extraFotosFiles: 0,
      planOfBestandenFiles: 0,
      locale: "nl"
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateFileCount = this.updateFileCount.bind(this);
  }

  handleSubmit(values, formikBag) {
    this.setState({ loading: true });

    const success = response => {
      if (response.data === "success") {
        setTimeout(() => {
          this.setState({ loading: false, success: true, error: false });
        }, 1000);
      } else {
        this.setState({ loading: false, success: false, error: response.data });
      }
    };
    const error = error => {
      let errorMessage = "";
      if (error.response) {
        errorMessage = error.response.data;
      } else if (error.request) {
        errorMessage = error.request.responseText;
      } else {
        errorMessage = error.message;
      }
      setTimeout(() => {
        this.setState({ loading: false, success: false, error: errorMessage });
      }, 1000);
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}submit.php`, qs.stringify(values))
      .then(success)
      .catch(error);
  }

  updateFileCount(field) {
    const count = this.state[field] + 1;
    this.setState({ [field]: count });
    return count;
  }

  render() {
    return (
      <IntlProvider key={this.state.locale} locale={this.state.locale} messages={messages[this.state.locale]}>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Quality Chape</title>
          </Helmet>
          <Spinner show={this.state.loading} />
          <div className="header">
            <img src={logo} alt="Logo" />
          </div>

          <div className="container main">
            {this.state.success && (
              <div className="card">
                <div className="success">
                  <h1>
                    <FormattedMessage id="Success!" />
                  </h1>
                  <p className="lead">
                    <FormattedMessage id="Form has been submitted." />
                  </p>
                </div>
              </div>
            )}

            <div className="flags">
              <div
                className={`flag` + (this.state.locale === "nl" ? " active" : "")}
                onClick={event => {
                  this.setState({ locale: "nl" });
                }}>
                <img src={flag_nl} alt="" /> NL
              </div>
              <div
                className={`flag` + (this.state.locale === "fr" ? " active" : "")}
                onClick={event => {
                  this.setState({ locale: "fr" });
                }}>
                <img src={flag_fr} alt="" /> FR
              </div>
            </div>

            {!this.state.success && (
              <Formik
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                validationSchema={validationSchema}
                render={({ errors, status, touched, isSubmitting, setFieldValue, values }) => {
                  // console.log(values);
                  return (
                    <Form noValidate>
                      <div className="card">
                        <div className="card-body rows">
                          {/*
                   <div className="row">
                      <div className="col-md-6">
                        <Field label="Offerte nr" name="quotationID" component={CustomInputComponent} />
                      </div>
                      <div className="col-md-6">
                        <Field label="Projectmanager" name="projectManager" component={CustomInputComponent} />
                      </div>
                    </div>
                    */}
                          <div className="row">
                            <div className="col-12">
                              <h5 className="mb-4">
                                <FormattedMessage id="Gegevens" />
                              </h5>
                            </div>
                            <div className="col-sm-6">
                              <Field
                                label={<FormattedMessage id="Naam / Firma" />}
                                name="factuur_naam"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                label={<FormattedMessage id="Juridische vorm" />}
                                name="factuur_juridische"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-12 mt-3">
                              <Field
                                label={<FormattedMessage id="Straat + nr" />}
                                name="factuur_straat"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-sm-6 mt-3">
                              <Field
                                label={<FormattedMessage id="Postcode" />}
                                name="factuur_postcode"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-sm-6 mt-3">
                              <Field
                                label={<FormattedMessage id="Gemeente" />}
                                name="factuur_gemeente"
                                component={CustomInputComponent}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="taxRate"
                                  id="taxRate1"
                                  value="6%"
                                />
                                <label className="form-check-label" htmlFor="taxRate1">
                                  6%
                                </label>
                              </div>
                            </div>
                            <div className="col-auto mt-0">
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="taxRate"
                                  id="taxRate2"
                                  value="21%"
                                />
                                <label className="form-check-label" htmlFor="taxRate2">
                                  21%
                                </label>
                              </div>
                            </div>
                            <div className="col-auto mt-0">
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="taxRate"
                                  id="taxRate3"
                                  value="Zelfstandige"
                                />
                                <label className="form-check-label" htmlFor="taxRate3">
                                  <FormattedMessage id="Zelfstandige" />
                                </label>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="row align-items-center ">
                                <div className="col-auto">
                                  <div className="form-check">
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="taxRate"
                                      id="taxRate4"
                                      value="btwNr"
                                    />
                                    <label className="form-check-label" htmlFor="taxRate4">
                                      <FormattedMessage id="B.T.W. nr." />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <Field
                                    className={
                                      `form-control` + (touched["btwNrVal"] && errors["btwNrVal"] ? ` is-invalid` : ``)
                                    }
                                    name="btwNrVal"
                                    id="taxRateOther"
                                  />
                                  {touched["btwNrVal"] && errors["btwNrVal"] && (
                                    <div className="invalid-feedback">{errors["btwNrVal"]}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {touched["taxRate"] && errors["taxRate"] && (
                              <div className="col-12">
                                <div className="invalid-feedback d-block">{errors["taxRate"]}</div>
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                label={<FormattedMessage id="Naam contactpersoon" />}
                                name="naam_contactpersoon"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                label={<FormattedMessage id="E-mail" />}
                                name="email"
                                type="email"
                                component={CustomInputComponent}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-auto mb-4">
                              <h5>
                                <FormattedMessage id="Werfadres" />
                              </h5>
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="idem_gegevens"
                                  id="idem_gegevens"
                                />
                                <label className="form-check-label" htmlFor="idem_gegevens">
                                  <FormattedMessage id="Idem Gegevens" />
                                </label>
                              </div>
                            </div>

                            <div className="col-12 mb-3 mt-0">
                              <Field
                                label={<FormattedMessage id="Straat + nr" />}
                                name="werfadres_straat"
                                component={CustomInputComponent}
                                disabled={values.idem_gegevens}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                label={<FormattedMessage id="Postcode" />}
                                name="werfadres_postcode"
                                component={CustomInputComponent}
                                disabled={values.idem_gegevens}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                label={<FormattedMessage id="Gemeente" />}
                                name="werfadres_gemeente"
                                component={CustomInputComponent}
                                disabled={values.idem_gegevens}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                label={<FormattedMessage id="G.S.M 1" />}
                                name="gsm_1"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                label={<FormattedMessage id="G.S.M 2" />}
                                name="gsm_2"
                                component={CustomInputComponent}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6 col-md-8">
                              <Field
                                label={
                                  <FormattedMessage id="Check-in-at-Work nr (in te vullen indien nodig werven groter dan 500.000.00€)" />
                                }
                                name="check_in_at_work_nr"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <FileUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                name="checkInAtWorkFiles"
                                updateFileCount={this.updateFileCount}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                label={<FormattedMessage id="Projectnaam" />}
                                name="project_naam"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                label={<FormattedMessage id="Referentie / Projectnummer" />}
                                name="referentie"
                                component={CustomInputComponent}
                              />
                            </div>
                          </div>

                          {/*------------------------------------------------*/}
                          <div className="row">
                            <label htmlFor="canPlaceTruck1" className="col-sm-6 col-form-label">
                              <FormattedMessage id="Mogelijk om vrachtwagen te plaatsen 20LM" />
                            </label>
                            <div className="col-sm-6">
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="canPlaceTruck"
                                  id="canPlaceTruck1"
                                  value="Ja"
                                />
                                <label className="form-check-label" htmlFor="canPlaceTruck1">
                                  {<FormattedMessage id="Ja" />}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="canPlaceTruck"
                                  id="canPlaceTruck2"
                                  value="Neen"
                                />
                                <label className="form-check-label" htmlFor="canPlaceTruck2">
                                  {<FormattedMessage id="Neen" />}
                                </label>
                              </div>
                            </div>
                          </div>
                          {/*------------------------------------------------*/}
                          <div className="row">
                            <label htmlFor="parkingBanRequired1" className="col-sm-6 col-form-label">
                              <FormattedMessage id="Parkeerverbod nodig" />
                            </label>
                            <div className="col-sm-6">
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="parkingBanRequired"
                                  id="parkingBanRequired1"
                                  value="Ja"
                                />
                                <label className="form-check-label" htmlFor="parkingBanRequired1">
                                  {<FormattedMessage id="Ja" />}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="parkingBanRequired"
                                  id="parkingBanRequired2"
                                  value="Neen"
                                />
                                <label className="form-check-label" htmlFor="parkingBanRequired2">
                                  {<FormattedMessage id="Neen" />}
                                </label>
                              </div>
                            </div>
                          </div>
                          {/*------------------------------------------------*/}
                          <div className="row">
                            <label htmlFor="waterPresentOnSite1" className="col-sm-6 col-form-label">
                              <FormattedMessage id="Water aanwezig op werf" />
                            </label>
                            <div className="col-sm-6">
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="waterPresentOnSite"
                                  id="waterPresentOnSite1"
                                  value="kraan"
                                />
                                <label className="form-check-label" htmlFor="waterPresentOnSite1">
                                  <FormattedMessage id="Kraan" />
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="waterPresentOnSite"
                                  id="waterPresentOnSite2"
                                  value="waterput"
                                />
                                <label className="form-check-label" htmlFor="waterPresentOnSite2">
                                  <FormattedMessage id="Waterput" />
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="waterPresentOnSite"
                                  id="waterPresentOnSite3"
                                  value="standpijp"
                                />
                                <label className="form-check-label" htmlFor="waterPresentOnSite3">
                                  <FormattedMessage id="Standpijp" />
                                </label>
                              </div>
                            </div>
                          </div>
                          {/*------------------------------------------------*/}
                          <div className="row">
                            <label htmlFor="mixerCleaningPossible1" className="col-sm-6 col-form-label">
                              <FormattedMessage id="Mengvat mogelijk uit te kuisen op de werf" />
                            </label>
                            <div className="col-sm-6">
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="mixerCleaningPossible"
                                  id="mixerCleaningPossible1"
                                  value="Ja"
                                />
                                <label className="form-check-label" htmlFor="mixerCleaningPossible1">
                                  {<FormattedMessage id="Ja" />}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="mixerCleaningPossible"
                                  id="mixerCleaningPossible2"
                                  value="Neen"
                                />
                                <label className="form-check-label" htmlFor="mixerCleaningPossible2">
                                  {<FormattedMessage id="Neen" />}
                                </label>
                              </div>
                            </div>
                          </div>
                          {/*------------------------------------------------*/}
                          <div className="row">
                            <div className="col-sm-6 col-md-4">
                              <h6>
                                <FormattedMessage id="Google maps screenshot" />
                              </h6>
                              <FileUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                name="mapsScreenshotFiles"
                                updateFileCount={this.updateFileCount}
                              />
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <h6>
                                <FormattedMessage id="Neem foto van de straat" />
                              </h6>
                              <FileUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                name="neemFotoVanDeStraatFiles"
                                updateFileCount={this.updateFileCount}
                              />
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <h6>
                                <FormattedMessage id="Neem foto van de werf" />
                              </h6>
                              <FileUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                name="neemFotoVanDeWerfFiles"
                                updateFileCount={this.updateFileCount}
                              />
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <h6>
                                <FormattedMessage id="Neem foto van de woning" />
                              </h6>
                              <FileUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                name="neemFotoVanDeWoningFiles"
                                updateFileCount={this.updateFileCount}
                              />
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <h6>
                                <FormattedMessage id="Neem foto van de werken" />
                              </h6>
                              <FileUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                name="neemFotoVanDeWerkenFiles"
                                updateFileCount={this.updateFileCount}
                              />
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <h6>
                                <FormattedMessage id="Extra foto's" />
                              </h6>
                              <FileUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                name="extraFotosFiles"
                                updateFileCount={this.updateFileCount}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*------------------------------------------------*/}

                      <div className="card shadow-sm mt-4">
                        <div className="card-header">
                          <FormattedMessage id="Chape" />
                        </div>
                        <div className="card-body pb-0">
                          <Measurements name="chape" values={values.chape} />
                        </div>
                      </div>

                      {/*------------------------------------------------*/}

                      <div className="card shadow-sm mt-4">
                        <div className="card-header">
                          <FormattedMessage id="Akoestische isolatie" />
                        </div>
                        <div className="card-body rows">
                          <div className="row">
                            <div className="col-sm-2">
                              <label>
                                <FormattedMessage id="Beschrijving" />
                              </label>
                            </div>
                            <div className="col-sm-10">
                              <Field name="akoestische_isolatie_info" component={CustomInputComponent} />
                            </div>
                            <div className="col-12 mt-3">
                              <Measurements
                                name="akoestische_isolatie"
                                unit1="m²"
                                unit2="mm"
                                values={values.akoestische_isolatie}
                                max="1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*------------------------------------------------*/}

                      <div className="card shadow-sm mt-4">
                        <div className="card-header">
                          <FormattedMessage id="Gespoten PUR isolatie" />
                        </div>
                        <div className="card-body pb-0">
                          <Measurements
                            name="gespoten_pur_isolatie"
                            values={values.gespoten_pur_isolatie}
                            options={[
                              { k: "afschuren", label: "Afschuren" },
                              { k: "aftoppen", label: "Aftoppen" },
                              { k: "vloerverwarming", label: "Vloerverwarming" }
                            ]}
                          />
                        </div>
                      </div>

                      {/*------------------------------------------------*/}

                      <div className="card shadow-sm mt-4">
                        <div className="card-header">
                          <FormattedMessage id="Schuimbeton" />
                        </div>
                        <div className="card-body pb-0">
                          <Measurements name="schuimbeton" values={values.schuimbeton} />
                        </div>
                      </div>

                      {/*------------------------------------------------*/}

                      <div className="card shadow-sm mt-4">
                        <div className="card-header">
                          <FormattedMessage id="EPS" />
                        </div>
                        <div className="card-body pb-0">
                          <Measurements name="eps" values={values.eps} />
                        </div>
                      </div>

                      {/*------------------------------------------------*/}

                      <div className="card shadow-sm mt-4">
                        <div className="card-header">
                          <FormattedMessage id="Beton" />
                        </div>
                        <div className="card-body pb-0">
                          <Measurements name="beton" values={values.beton} options={[{ k: "poly_beton", label: "Poly Beton" }]} />
                        </div>
                      </div>

                      {/*------------------------------------------------*/}

                      <div className="card shadow-sm mt-4">
                        <div className="card-body rows">
                          <div className="row">
                            <label htmlFor="WerkenZijn1" className="col-sm-4 col-form-label">
                              <FormattedMessage id="Werken zijn" />
                            </label>
                            <div className="col-sm-8">
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="WerkenZijn"
                                  id="WerkenZijn1"
                                  value="Binnen"
                                />
                                <label className="form-check-label" htmlFor="WerkenZijn1">
                                  <FormattedMessage id="Binnen" />
                                </label>
                              </div>
                              <div className="form-check form-check-inline ml-1">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="WerkenZijn"
                                  id="WerkenZijn2"
                                  value="Buiten"
                                />
                                <label className="form-check-label" htmlFor="WerkenZijn2">
                                  <FormattedMessage id="Buiten" />
                                </label>
                              </div>
                              <div className="form-check form-check-inline ml-1">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="WerkenZijn"
                                  id="WerkenZijn3"
                                  value="Binnen & Buiten"
                                />
                                <label className="form-check-label" htmlFor="WerkenZijn3">
                                  <FormattedMessage id="Binnen & Buiten" />
                                </label>
                              </div>
                            </div>
                          </div>

                          {/*------------------------------------------------*/}

                          <div className="row">
                            <label htmlFor="WelkeVerdiepen" className="col-sm-4 col-form-label">
                              <FormattedMessage id="Welke verdiepen" />
                            </label>
                            <div className="col-md-8">
                              <WelkeVerdiepen values={values.welkeVerdiepen} />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <FormattedMessage id="Plan en/of bestanden uploaden" />
                            </div>
                            <div className="col-md-4">
                              <FileUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                name="planOfBestandenFiles"
                                updateFileCount={this.updateFileCount}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="form-check form-check-inline">
                                <label className="form-check-label" htmlFor="inlineCheckbox1">
                                  <FormattedMessage id="Extra info" />
                                </label>
                                <Field
                                  className="form-check-input ml-3"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="option1"
                                  onClick={event => {
                                    this.setState({ addExtraInfo: event.target.checked });
                                  }}
                                />
                              </div>
                              {this.state.addExtraInfo && (
                                <Field component="textarea" rows="5" name="extra_info" className="form-control mt-3" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.state.error && (
                        <div className="alert alert-danger mt-5">
                          <FormattedMessage id="There was a problem submitting your request." /> <br />
                          {this.state.error} <br />
                          <FormattedMessage id="Please try again, if problem persists kindly contact us at " />
                          <a href="mailto:info@qualitychape.be">info@qualitychape.be</a>
                        </div>
                      )}

                      <div className="row justify-content-center my-5">
                        <div className="col-md-6">
                          <button type="submit" className="btn btn-primary btn-lg btn-block">
                            <FormattedMessage id="Verzenden" />
                          </button>
                        </div>
                      </div>

                      <p className="text-center">
                        <FormattedMessage id="NOTICE" />
                      </p>

                      {/*------------------------------------------------*/}
                      <ErrorFocus />
                    </Form>
                  );
                }}
              />
            )}
          </div>
        </div>
      </IntlProvider>
    );
  }
}

export default App;
