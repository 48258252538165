import React from "react";
import { Field, FieldArray } from "formik";

//translations

import { FormattedMessage } from "react-intl";

export const CustomInputComponent = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => {
	const isInvalid = touched[field.name] && errors[field.name];
	return (
		<div>
			{props.label && <label htmlFor={field.name}>{props.label}</label>}
			<input type="text" className={`form-control` + (isInvalid ? ` is-invalid` : ``)} {...field} {...props} />
			{isInvalid && <div className="invalid-feedback">{errors[field.name]}</div>}
		</div>
	);
};

export const MeasurementInputComponent = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => {
	const isInvalid = touched[field.name] && errors[field.name];
	return (
		<div className="input-group flex-nowrap">
			<input className={`form-control` + (isInvalid ? ` is-invalid` : ``)} {...field} {...props} />
			<div className="input-group-append">
				<span className="input-group-text">{props.unit}</span>
			</div>
			{isInvalid && <div className="invalid-feedback">{errors[field.name]}</div>}
		</div>
	);
};

export const Measurements = ({ name, values = [], options = [], max = 10, unit1 = "m²", unit2 = "cm" }) => {
	return (
		<FieldArray
			name={name}
			render={arrayHelpers => (
				<div>
					{values.map((measurement, index) => (
						<div className="row form-group" key={index}>
							<div className="col-6 col-md-2">
								<label htmlFor={`${name}[${index}].oppervlakte`} className="col-form-label">
									<FormattedMessage id="Oppervlakte" />
								</label>
							</div>
							<div className="col-6 col-md-2">
								<Field
									type="number"
									id={`${name}[${index}].oppervlakte`}
									name={`${name}[${index}].oppervlakte`}
									unit={unit1}
									component={MeasurementInputComponent}
								/>
							</div>
							<div className="col-6 col-md-3 offset-md-1">
								{options &&
									options.map((item, i) => (
										<div className="form-check ml-1" key={`${index}${i}`}>
											<Field
												className="form-check-input"
												type="checkbox"
												name={`${name}[${index}].${item.k}`}
												id={`${name}[${index}].${item.k}`}
											/>
											<label className="form-check-label" htmlFor={`${name}[${index}].${item.k}`}>
												<FormattedMessage id={`${item.label}`} />
											</label>
										</div>
									))}
							</div>
							<div className="col-6 col-md-2 text-sm-right">
								<label htmlFor={`${name}[${index}].dikte`} className="col-form-label">
									<FormattedMessage id="Dikte" />
								</label>
							</div>
							<div className="col-6 col-md-2">
								<Field
									type="number"
									id={`${name}[${index}].dikte`}
									name={`${name}[${index}].dikte`}
									unit={unit2}
									component={MeasurementInputComponent}
								/>
							</div>
						</div>
					))}
					{max > 1 && (
						<div className="row form-group mt-4 justify-content-end">
							<div className="col-6 col-md-3 text-sm-right">
								<button
									type="button"
									className="btn btn-primary"
									disabled={!(values.length < max)}
									onClick={() => arrayHelpers.push({ oppervlakte: "", dikte: "" })}>
									<FormattedMessage id="Voeg een nieuwe regel toe" />
								</button>
							</div>
						</div>
					)}
				</div>
			)}
		/>
	);
};

export const WelkeVerdiepen = ({ values }) => (
	<FieldArray
		name="welkeVerdiepen"
		render={(arrayHelpers, form) => (
			<div>
				{["-1", "0", "1", "2", "3", "4", "5+"].map((item, index) => (
					<div className="form-check form-check-inline ml-1" key={index}>
						<Field
							className="form-check-input"
							type="checkbox"
							name="welkeVerdiepen"
							id={`welkeVerdiepen.${index}`}
							value={item}
							onChange={event => {
								arrayHelpers.replace(index, event.target.checked ? event.target.value : "");
							}}
						/>
						<label className="form-check-label" htmlFor={`welkeVerdiepen.${index}`}>
							{item}
						</label>
					</div>
				))}
			</div>
		)}
	/>
);
