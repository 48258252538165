import React from "react";
// FilePond
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileRename, FilePondPluginImageTransform, FilePondPluginImageResize);

export default class FileUpload extends React.Component {
	render() {
		const { values, setFieldValue, name, updateFileCount } = this.props;

		return (
			<FilePond
				labelIdle="UPLOAD"
				server={process.env.REACT_APP_API_URL}
				allowMultiple={true}
				imageTransformOutputQuality="80"
				imageResizeTargetWidth="1200"
				imageResizeTargetHeight="1200"
				fileRenameFunction={file => {
					const count = updateFileCount(name);
					const filename =
						new Date()
							.toISOString()
							.slice(0, 19)
							.replace(/-|:|T/g, "") + `_${name}_${count}${file.extension}`;
					return filename;
				}}
				onprocessfile={(error, item) => {
					setFieldValue("attachments", [...values.attachments, item.filename]);
					setFieldValue("filepond", [...values.filepond, item.serverId]);
				}}
				onremovefile={file => {
					const attachments = values.attachments.filter(el => el !== file.filename);
					setFieldValue("attachments", attachments);
				}}
			/>
		);
	}
}
